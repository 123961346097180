import * as React from "react"
import { useState } from "react"
import { Link, graphql } from "gatsby"
import { MapContainer, TileLayer, Marker, Popup, Icon } from "react-leaflet"
import _ from "underscore"

import Layout from "../components/layout"
import Seo from "../components/seo"

const generateTags = (data) => {
  let allTags = data.allMdx.nodes.map(node => {
    return node.frontmatter.tags.split(',').map(el => { return el.trim() })
  })
  allTags = _.flatten(allTags)
  allTags = _.uniq(allTags)
  return allTags
}

const nodeIncludesTag = (node, tag) => {
  return node.frontmatter.tags.includes(tag)
}

const IndexPage = ({ data }) => {
  const [selectedTag, setSelectedTag] = useState('')
  const [showList, setShowList] = useState(false)
  const centerMapPosition = [50.72753, 7.11335]

  const onSelectTag = (tag) => {
    setSelectedTag(tag)
  }

  const onShowList = (trueOrFalse) => {
    setShowList(trueOrFalse)
  }

  return (
    <Layout>
      <Seo title="City Stories" />
      <h3>Filter</h3>
      <div id="tagcloud">
        {
          generateTags(data).map(node => (
            <span style={{ padding: `12px 12px 12px 0`, fontSize: `24px` }}><a class={ node === selectedTag ? 'selected' : '' } onClick={() => { onSelectTag(node) }}>{`#${node.replace(/ /g,'')}`}</a></span>
          ))
        }
        </div>
        <p>{ selectedTag &&
          <>
            <h5>Aktiver Filter:</h5>
            { `#${selectedTag.replace(/ /g,'')}` } <span style={{ padding: `12px`, fontSize: `12px` }}><a onClick={() => { onSelectTag('') }}>x</a></span>
          </>
        }
        </p>
        <>
          <div id="maptoggle" style={{ paddingBottom: `12px` }}><a onClick={() => onShowList(false)} class={showList ? '' : 'selected'}>Karte</a>/<a onClick={() => onShowList(true)} class={showList ? 'selected' : ''}>Liste</a></div>
        </>
      { 
        !showList &&
        typeof window !== 'undefined' && 
          <MapContainer id="totalMap" center={centerMapPosition} zoom={14} scrollWheelZoom={false}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {
              data.allMdx.nodes.map(node => (
                nodeIncludesTag(node, selectedTag) &&
                <Marker position={[node.frontmatter.lat, node.frontmatter.lng]} key={node.id} opacity={node.frontmatter.code === '0' ? 0.5 : 1.0}>
                  <Popup key={node.id}>
                    <Link to={`../` + node.slug.toLowerCase()}>{node.frontmatter.title}</Link> <br /> { node.frontmatter.sub_title }
                  </Popup>
                </Marker>
              ))
            }
        </MapContainer>
      }
      { 
        showList &&
        <ul>
          {
            data.allMdx.nodes.map(node => (
            nodeIncludesTag(node, selectedTag) &&
            <li key={node.id}><Link to={"/" + node.slug.toLowerCase()}>{node.frontmatter.code} - {node.frontmatter.title}</Link> { node.frontmatter.sub_title }</li>
            ))
          }
      </ul>
      }
    </Layout>
  )
}

export const query = graphql`
  query 
  { 
    allMdx(filter: {slug: {glob: "!*.en"}}) {
      nodes {
        frontmatter {
          title
          sub_title
          code
          lat
          lng
          tags
        }
        id
        body
        slug
      }
    }
  }
`

export default IndexPage
